<template>
  <base-layout>
    <InvoiceModal :trip="invoiceModalDetails" :symbol="symbol" />

    <!-- <RefundModal @refunded="onRefunded" /> -->

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <title-plus
              :title="$t('components.triggerPaymentManagement.headline')"
              :hide-plus="true"
            />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="
              $t(
                'components.triggerPaymentManagement.summary.totalTriggerPayment'
              )
            "
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary.total_debit}`
            "
            variant="gray"
          />

          <SummaryCard
            :title="
              $t(
                'components.triggerPaymentManagement.summary.successfulPayment'
              )
            "
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary
                .trigger_succeeded || '0.00'}`
            "
            variant="gray"
          />

          <SummaryCard
            :title="
              $t(
                'components.triggerPaymentManagement.summary.unsuccessfulPayment'
              )
            "
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary
                .trigger_failed || '0.00'}`
            "
            variant="gray"
          />
        </div>
      </div>
      <template v-if="$acl.canView('transaction')">
        <FSTable
          :fst-id="`triggerPayment`"
          :endpoint="getEndpoint"
          :headers="getTableHeaders"
          :qso="{ prepend: '', append: '' }"
          :currency-enabled="true"
          exportFor="triggerPayment"
          :currency-attributes="getCurrencyAttributes"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="`triggerPayment`"
                :slot-width="slotWidth"
                :options="filterOptions"
              />
            </keep-alive>
          </template>

          <template v-slot:default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" @meta="(e) => (indexMetaData = e)">
                  <FSTableRowItem
                    :text="item.id"
                    :truncate="-5"
                    :to="{ name: 'ViewTrip', params: { id: item.trip } }"
                    v-if="item.trip && item.trip !== ''"
                  />

                  <FSTableRowItem
                    :text="item.id"
                    :truncate="-5"
                    :truncate-prepend="`#`"
                    v-if="!item.trip || item.trip === ''"
                  />

                  <FSTableRowItem>
                    <router-link
                      v-if="item.user"
                      :to="{
                        name: 'ViewRiderUserProfile',
                        params: { id: item.user.id },
                      }"
                      class="font-extrabold text-blue-600 capitalize"
                      target="_blank"
                    >
                      {{ item.user.full_name }}
                    </router-link>
                    <span v-else>
                      --
                    </span>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <i
                      :class="
                        getPaymentCardIconClass(item.payment_method_details)
                      "
                      v-if="item.payment_method && item.payment_method !== '--'"
                    />
                    <span
                      v-if="item.payment_method && item.payment_method !== '--'"
                      >&nbsp;&nbsp;</span
                    >
                    {{ item.payment_method }}
                  </FSTableRowItem>

                  <FSTableRowItem :text="item" as-currency />

                  <FSTableRowItem :text="item.checkout.updated_at" date />

                  <FSTableRowItem :text="item.checkout.checkout_status" />

                  <FSTableRowItem
                    :text="item.checkout.retry_count.toString()"
                  />

                  <FSTableRowItem
                    :text="item.payment_reference_id"
                    :text-fallback="`--`"
                  >
                    <template #default="{ text }">
                      <span v-if="text === '--'">--</span>
                      <span v-else>
                        <span
                          v-if="paymentMethod === 'Stripe'"
                          class="text-blue-500"
                        >
                          <a
                            target="_blank"
                            :href="
                              `https://dashboard.stripe.com/payments/${text}`
                            "
                            >{{ truncate(text, -5, '') }}</a
                          >
                          <span
                            class="cursor-pointer text-gray-500"
                            @click="
                              copyTextToClipboard(
                                `https://dashboard.stripe.com/payments/${text}`
                              )
                            "
                          >
                            <i class="ml-2 far fa-copy"></i>
                          </span>
                        </span>
                        <span v-else>{{ truncate(text, -5, '') }}</span>
                      </span>
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div class="flex gap-1">
                      <div
                        v-if="
                          item.checkout.checkout_status !== 'FINALIZE_SUCCESS'
                        "
                      >
                        <div
                          v-if="item.checkout.result_description"
                          class="refund-action refund"
                          v-tooltip.top="`${item.checkout.result_description}`"
                          @click="onTrigger({ tripId: item.id })"
                        >
                          Trigger
                        </div>
                        <div
                          v-else
                          class="refund-action refund"
                          @click="onTrigger({ tripId: item.id })"
                        >
                          Trigger
                        </div>
                      </div>
                      <div
                        class="refund-action refund disabled"
                        v-if="
                          item.checkout.checkout_status === 'FINALIZE_SUCCESS'
                        "
                      >
                        Trigger
                      </div>
                      <MoreActionsDropdown
                        :key="`more-actions-${itemIndex}`"
                        :id="`more-actions-${itemIndex}`"
                        :data="item"
                        @delete="$store.dispatch('fsTable/fetchData')"
                      />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  @meta="(e) => (indexMetaData = e)"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="item.id"
                    :truncate="-5"
                    :truncate-prepend="`#`"
                    :to="{ name: 'ViewTrip', params: { id: item.trip } }"
                    v-if="item.trip && item.trip !== ''"
                  />

                  <FSTableRowItem
                    :text="item.id"
                    :truncate="-5"
                    :truncate-prepend="`#`"
                    v-if="!item.trip || item.trip === ''"
                  />

                  <FSTableRowItem :text="item.checkout.checkout_status" />

                  <FSTableRowItem :text="item" as-currency />
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.triggerPaymentManagement.table.columns.rider'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <router-link
                          v-if="item.user"
                          :to="{
                            name: 'ViewRiderUserProfile',
                            params: { id: item.user.id },
                          }"
                          class="font-extrabold text-blue-600 capitalize"
                          target="_blank"
                        >
                          {{ item.user.full_name }}
                        </router-link>
                        <span v-else>
                          --
                        </span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.triggerPaymentManagement.table.columns.creditCard'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <i
                          :class="
                            getPaymentCardIconClass(item.payment_method_details)
                          "
                          v-if="
                            item.payment_method && item.payment_method !== '--'
                          "
                        />
                        <span
                          v-if="
                            item.payment_method && item.payment_method !== '--'
                          "
                          >&nbsp;&nbsp;</span
                        >
                        {{ item.payment_method }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.triggerPaymentManagement.table.columns.lastTriggeredAt'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.checkout.updated_at,
                            'D MMM, YY hh:mm:ss a'
                          ) || `--`
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.triggerPaymentManagement.table.columns.retryCount'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.checkout.retry_count.toString() || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.triggerPaymentManagement.table.columns.paymentRef'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <span v-if="item.payment_reference_id">
                          <span
                            v-if="paymentMethod === 'Stripe'"
                            class="text-blue-500"
                          >
                            <a
                              target="_blank"
                              :href="
                                `https://dashboard.stripe.com/payments/${item.payment_reference_id}`
                              "
                              >{{
                                truncate(item.payment_reference_id, -5, '')
                              }}</a
                            >
                            <span
                              class="cursor-pointer text-gray-500"
                              @click="
                                copyTextToClipboard(
                                  `https://dashboard.stripe.com/payments/${item.payment_reference_id}`
                                )
                              "
                            >
                              <i class="ml-2 far fa-copy"></i>
                            </span>
                          </span>
                          <span v-else>{{
                            truncate(item.payment_reference_id, -5, '')
                          }}</span>
                        </span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.triggerPaymentManagement.table.columns.actions'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex gap-1">
                          <div
                            v-if="
                              item.checkout.checkout_status !==
                                'FINALIZE_SUCCESS'
                            "
                          >
                            <div
                              v-if="item.checkout.result_description"
                              class="refund-action refund"
                              v-tooltip.top="
                                `${item.checkout.result_description}`
                              "
                              @click="onTrigger({ tripId: item.id })"
                            >
                              {{
                                $t(
                                  'components.triggerPaymentManagement.actions.trigger'
                                )
                              }}
                            </div>
                            <div
                              v-else
                              class="refund-action refund"
                              @click="onTrigger({ tripId: item.id })"
                            >
                              {{
                                $t(
                                  'components.triggerPaymentManagement.actions.trigger'
                                )
                              }}
                            </div>
                          </div>
                          <div
                            class="refund-action refund disabled"
                            v-if="
                              item.checkout.checkout_status ===
                                'FINALIZE_SUCCESS'
                            "
                          >
                            {{
                              $t(
                                'components.triggerPaymentManagement.actions.trigger'
                              )
                            }}
                          </div>
                          <MoreActionsDropdown
                            :key="`more-actions-${itemIndex}`"
                            :id="`more-actions-${itemIndex}`"
                            :data="item"
                            @delete="$store.dispatch('fsTable/fetchData')"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>

      <div v-else class="py-5 font-bold text-center text-gray-600">
        {{ $t('components.acl.doNotHavePermission') }}
      </div>
    </content-section>
  </base-layout>
</template>
<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
  generateCurrencyAttributes,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { resolveProp } from '@/utils'
import { getFormattedDateTime } from '@/utils/datetime'
import { truncate } from '@/plugins/truncate'
import MoreActionsDropdown from '@/views/transactions/MoreActionsDropdown.vue'
export default {
  name: 'Transactions',
  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    // OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    // RefundModal: () =>
    //   import('../../composites/transaction/revenue/index/RefundPopup.vue'),
    InvoiceModal: () => import('@/components/modals/InvoiceModal'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    MoreActionsDropdown,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      symbol: '$',
      indexMetaData: {
        summary: {
          currency: {
            symbol: '',
          },
          total_credit: '0.00',
          avg_credit_per_day: '0.00',
          total_debit: '0.00',
          avg_debit_per_day: '0.00',
        },
        count: {
          total: 0,
        },
      },
      // indexDataEndpoint: TransactionConfig.api.index,

      tableHeaders: [
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.invoiceID'
          ),
          width: '9%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.rider'
          ),
          width: '10%',
          sort: 'user',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.creditCard'
          ),
          width: '10%',
          sort: 'tax',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.amount'
          ),
          width: '5%',
          sort: 'user',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.lastTriggeredAt'
          ),
          width: '13%',
          sort: 'date',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.lastTriggerStatus'
          ),
          width: '12%',
          sort: 'payment',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.retryCount'
          ),
          width: '10%',
          sort: 'payment_for',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.paymentRef'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.actions'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.invoiceID'
          ),
          width: '25%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.lastTriggerStatus'
          ),
          width: '35%',
          sort: 'checkout_status',
        },
        {
          text: this.$t(
            'components.triggerPaymentManagement.table.columns.amount'
          ),
          width: '30%',
          sort: 'amount',
        },
      ],
      opened: [],

      invoiceModalState: false,
      invoiceModalDetails: {},

      // generated by generateFilterOptions()
      filterOptions: null,
    }
  },

  computed: {
    getEndpoint() {
      return useEndpoints.invoice.indexOfTriggerPayment()
    },
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getCurrencyAttributes() {
      return generateCurrencyAttributes({
        root: '',
        // def: {
        //   amount: 'settled_amount',
        //   currencyName: 'settled_currency.name',
        //   currencySymbol: 'settled_currency.symbol',
        // },
        def: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
        actual: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
      })
    },
  },

  async created() {
    this.symbol = this.$org?.default_currency?.symbol || '$'
    await this.generateFilterOptions()
    const allPaymentGateways = await this.$http
      .get(useEndpoints.dropdown.paymentGateways())
      .then((res) => res.data.data)
      .catch((err) => console.log('paymentGatewayErr', err))
    const selectedPaymentGateway = allPaymentGateways.find(
      (pgItem) =>
        pgItem.id ===
        JSON.parse(localStorage.getItem('organization'))
          .default_payment_gateway_config.payment_gateway
    )
    this.paymentMethod = selectedPaymentGateway.name
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    truncate,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    copyTextToClipboard(link) {
      const el = document.createElement('textarea')
      el.value = link
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-99999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Payment Reference link Copied',
          text: 'The Payment Reference link has been copied to your clipboard',
        },
        2000
      )
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },

    async onTrigger({ tripId }) {
      if (this.$acl.canNotUpdate('transaction')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'transaction',
          })
        )
        return
      }

      this.$notify(
        {
          group: 'generic',
          type: 'Default',
          title: 'Attempting trigger payment',
          text: `The action is being executed. You'll be notified shortly.`,
        },
        1500
      )

      this.$http
        .post(useEndpoints.invoice.triggerNegativeBalanceResolver(tripId))
        .then(async (res) => {
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Trigger Payment',
            text: res.data.detail,
          })
          await this.$store.dispatch('fsTable/fetchData')
        })
        .catch((err) => {
          this.$notify({
            group: 'generic',
            type: 'danger',
            title: 'Failed to trigger payment',
            text: err.response.detail,
          })
        })
    },

    async onInvoiceModalReq(id, downloadOnly = false) {
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: `Processing`,
          text: 'The action is being executed...',
        },
        600
      )

      await this.$http
        .get(this.$config.trip.api.single(id), {
          params: {
            preferred_currency: resolveProp(
              'fsTable.organizationCurrency.selected',
              this.$store.state
            ),
          },
        })
        .then((res) => {
          this.invoiceModalDetails = res.data

          if (downloadOnly === true) {
            if (this.$refs.invoiceModal) {
              this.$refs.invoiceModal.exportToPDF()
            }
          } else {
            this.$modal.show('invoiceModal')
          }
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            },
            5000
          )
        })
    },

    async generateFilterOptions() {
      const statusOptions = [
        {
          text: 'Success',
          value: 'FINALIZE_SUCCESS',
        },
        {
          text: 'Failed',
          value: 'FAILED',
        },
      ]
      let filters = [
        {
          key: 'checkout_status',
          type: 'checkbox',
          input: statusOptions,
          title: 'Status',
        },
        {
          key: 'amount_range',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 1000,
            minQs: 'min_amount', // query string for min value
            maxQs: 'max_amount', // query string for max value
          },
          title: 'Amount',
        },
        {
          key: 'retry_range',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: '',
            max: 9,
            minQs: 'min_retry', // query string for min value
            maxQs: 'max_retry', // query string for max value
          },
          title: 'Retry',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },

    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.refund-action {
  color: #ffffff;
  width: 87px;
  height: 25px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  &.refund {
    background-color: #ff5500;
  }

  &.refunded {
    background-color: #c7c7c7;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
